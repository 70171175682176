footer.footer {
  background-color: var(--black);
  color: var(--off-white);
  font-size: 14px;
}
footer.footer .footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}