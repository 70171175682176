.contact-section {
  background-image: url(/src/images/contactBackground.svg);
  background-size: cover;
  background-position: top center;
}
.contact-section .row-wrapper .col {
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}