.blinking-underscore {
  color: var(--accent);
  animation: blink 0.6s linear infinite alternate;
}

@keyframes blink {
  from {
    color: var(--accent);
  }
  to {
    color: transparent;
  }
}