.site-section {
  padding-top: 75px;
  padding-bottom: 75px;
}
.site-section .row-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;
}
.site-section .row-wrapper .col {
  order: 1;
}
@media (min-width: 768px) {
  .site-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .site-section .row-wrapper .col.mobile-primary {
    order: 0;
  }
}
@media (min-width: 992px) {
  .site-section .row-wrapper {
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
  }
}