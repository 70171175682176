/* Header Stylings */

.header {
  background-color: var(--white);
  position: sticky;
  top: 0;
  height: var(--headerHeight);
  z-index: 1;
}
.header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header .logo-wrapper img {
  max-width: 100%;
  max-height: 100%;
  width: 120px;
}

.header .nav-wrapper {
  position: relative;
}

.header .nav-wrapper ul {
  list-style: none;
}

.header .nav-wrapper ul li {
  margin: 20px 0;
}

.header .nav-wrapper ul li a {
  text-decoration: none;
}

.header .nav-wrapper .desktop-nav {
  display: none;
}

.header .nav-wrapper .desktop-nav ul {
  display: flex;
  flex-direction: row;
  gap: 50px;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.header .nav-wrapper .desktop-nav ul li {
  cursor: pointer;
}

.header .nav-wrapper .desktop-nav ul li::after {
  content: '';
  height: 3px;
  width: 0;
  display: block;
  margin: 0 auto;
  background-color: var(--accent);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.header .nav-wrapper .desktop-nav ul li:hover::after {
  width: 25px;
}

.header .nav-wrapper .desktop-nav ul li a {
  color: var(--bodyFontColor);
}

.header .nav-wrapper .desktop-nav ul li a:hover {
  color: var(--bodyFontColor);
}

.header .nav-wrapper .mobile-nav-toggle {
  display: flex;
  flex-direction: column;
  width: 40px;
  gap: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.header .nav-wrapper .mobile-nav-toggle .hamburger-line {
  height: 2px;
  background-color: var(--black);
  width: 100%;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.header .nav-wrapper .mobile-nav-toggle.active .hamburger-line {
  background-color: var(--white);
}
.header .nav-wrapper .mobile-nav-toggle.active .top-line {
  display: none;
}
.header .nav-wrapper .mobile-nav-toggle.active .center-line {
  transform: rotate(45deg);
}
.header .nav-wrapper .mobile-nav-toggle.active .bottom-line {
  position: absolute;
  transform: rotate(-45deg);
}

.header .nav-wrapper .mobile-nav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.header .nav-wrapper .mobile-nav.open {
  left: 0;
}

.header .nav-wrapper .mobile-nav ul li {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.header .nav-wrapper .mobile-nav ul li:hover {
  transform: translate(10px, 0);
}

.header .nav-wrapper .mobile-nav ul li a {
  color: var(--white);
  font-size: 4rem;
}

.header .nav-wrapper .mobile-nav ul li a:hover {
  color: var(--off-white);
}

@media (min-width: 992px) {
  .header .nav-wrapper .mobile-nav,
  .header .nav-wrapper .mobile-nav-toggle {
    display: none;
  }

  .header .nav-wrapper .desktop-nav {
    display: block;
  }
}