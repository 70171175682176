.hero-section .row-wrapper .col-left .text-container {
  max-width: 500px;
}

.hero-section .row-wrapper .col-right {
  position: relative;
}

.hero-section .row-wrapper .col-right .svg-blob {
  position: absolute;
  left: 0;
  top: -20px;
}
.hero-section .row-wrapper .col-right .svg-blob svg {
    width: 100vw;
    height: auto;
    max-width: 550px;
}
@media (min-width: 768px) {
  .hero-section .row-wrapper .col-right .svg-blob svg {
      width: auto;
      height: auto;
  }
}

.hero-section .row-wrapper .col-right .circle-img {
  max-width: 450px;
  position: relative;
}
