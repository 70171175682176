/* Primary CTA */
.primary-cta {
  text-decoration: none;
  background-color: var(--accent);
  padding: 2.1rem 4.2rem;
  font-size: 1.8rem;
  color: var(--black);
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--btnFont);
  border-radius: var(--borderRadius);
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.primary-cta:hover {
  background-color: var(--accent-100);
}

/* Secondary CTA */
.secondary-cta {
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.secondary-cta:hover {
  text-decoration: underline;
}

.secondary-cta .arrow {
  vertical-align: middle;
  margin-left: 10px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.secondary-cta:hover .arrow {
  margin-left: 20px;
}