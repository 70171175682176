/* CUSTOM PROPERTIES */

:root {
  /* - Colors - */
  --accent: #EE916C;
  --accent-100: #f8a27c;
  --black: #111111;
  --white: #ffffff;
  --off-white: #cdcdcd;
  --gray: #777777;

  /* - Fonts - */
  --headingFont: 'Raleway', sans-serif;
  --headingFontWeight: 600;
  --headingFontColor: var(--black);
  --bodyFont: 'Poppins', sans-serif;
  --bodyFontWeight: 300;
  --bodyFontColor: var(--black);
  --btnFont: 'Raleway', sans-serif;

  /* - Misc - */
  --borderRadius: 8px;
  --boxShadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);

  /* - General - */
  --siteBackground: var(--white);

  /* Header */
  --headerHeight: 130px;

}

/* General */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* 62.5% of 16px browser font size is 10px */
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: var(--headerHeight);
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  font-family: var(--bodyFont);
  font-weight: var(--bodyFontWeight);
  color: var(--bodyFontColor);
  background-color: var(--siteBackground);
}

img {
  max-width: 100%;
}

img.circle-img {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  aspect-ratio: 1;
  width: 100%;
}

/* Text */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--headingFont);
  font-weight: var(--headingFontWeight);
  color: var(--headingFontColor);
}

h1, h2 {
  font-size: 5.2rem;
}

/* Links */
a {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

/* Layout */
.main-container {
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

div.spacer-sm {
  padding: 8px 0;
}
div.spacer-md {
  padding: 16px 0;
}

.cta-btns {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
