.skill-boxes {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  row-gap: 50px;
}

.skill-boxes .skill-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 33.33%;
}

.skill-boxes .skill-box .skill-icon {
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(320deg, var(--accent-100) 50%, var(--accent) 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: var(--black);
}

.skill-boxes .skill-box .skill-label {
  font-weight: 600;
}